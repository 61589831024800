armor_pieces = [
    "helmet",
    "chestplate",
    "leggings",
    "boots"
];
armor_types = [
    "chainmail",
    // "leather",
    "iron",
    "gold",
    "diamond",
    "netherite"
];

dieLayout = [

    [4],
    [0, 8],
    [0, 4, 8],
    [0, 2, 6, 8],
    [0, 2, 4, 6, 8],
    [0, 2, 3, 5, 6, 8],
]

trimModels = [
    "bolt",
    "coast",
    "dune",
    "eye",
    "flow",
    "host",
    "raiser",
    "rib",
    "sentry",
    "snout",
    "shaper",
    "spire",
    "tide",
    "vex",
    "wayfinder",
    "ward",
    "wild",
    "silence",
    
    "empty"
];
trimPalettes = [
    "amethyst",
    "copper",
    "diamond",
    "emerald",
    "gold",
    "iron",
    "lapis",
    "netherite",
    "quartz",
    "redstone"
];
premadeSkins = [
    "alex",
    "ari",
    "efe",
    "kai",
    "makena",
    "noor",
    "steve",
    "sunny",
    "zuri"
];
trimTexts = {
    "coast": {
        "text":"Coast templates can be found in shipwrecks",
        
    },
    "dune": {
        "text":"The Dune template can be found in the pyramids",
        
    },
    "eye": {
        "text":"1 Eye template is guaranteed in every Stronghold library chest",
        
    },
    "rib": {
        "text":"Rib templates can be found in chests in nether fortresses.",
        
    },
    "sentry": {
        "text":"Sentry templates are hidden in the pillager outpost chest",
        
    },
    "snout": {
        "text":"The Snout template can be found in bastion remnants",
        
    },
    "spire": {
        "text":"Travel to end cities to find the ",
        
    },
    "tide": {
        "text":"The tide template can be found in Ocean monuments",
        
    },
    "vex": {
        "text":"The Vax template can be found in chests in Woodland Mansions.",
        "more_info": "The Vax template can be found in chests in Woodland Mansions. however there are few chests in the mansions so it can be a bit challanging to find"
    },
    "ward": {
        "text":"The Ward template can be found in ancient cities",
        
    },
    "wild": {
        "text":"Wild template can be found in jungle temples",
        
    },

    "wayfinder": {
        "text":"This armor trim can be found in the trial ruins in suspicious gravel",
        
    },
    "raiser": {
        "text":"This armor trim can be found in the trial ruins in suspicious gravel",
        
    },
    "shaper": {
        "text":"This armor trim can be found in the trial ruins in suspicious gravel",
        
    },
    "host": {
        "text":"This armor trim can be found in the trial ruins in suspicious gravel",
        "obtain": "from gravel",
        
        
    },
    "silence": {
        "text":"Silence trims can be found in chests in ancient cities.",
        "more_info": "Silence trims can be found in chests in ancient cities. They have a low chance to be found so be aware that you might have to loot multiple cities before finding a template",
        "obtain": "from chest",
        "chance": 0.02
    },
};